import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import App from "../components/app"

import SEO from "../components/seo"

const Page = () => (
  <Layout>
    <SEO title="EasyEditText" />
    <div className="BasicPage">
      <App name="EasyEditText" imageName="/icon-easyedittext.jpeg" />
    </div>
  </Layout>
)

export default Page
